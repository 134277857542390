import React from "react";
import { Logo } from "./fa";
import { graphql, useStaticQuery } from "gatsby";

export const Title = ({ link }) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          menu {
            title
            icon
            link
            show
          }
        }
      }
    }
  `);

  const title = site.siteMetadata.menu.find(
    (menu) => (link.startsWith(menu.link) && menu.show) || link === menu.link
  );

  return (
    title && (
      <>
        <title>{title.title}</title>
        <div className="display-5 mb-3 mb-lg-5 text-center">
          <Logo className="text-secondary" icon={title.icon} />
          <span> {title.title}</span>
        </div>
      </>
    )
  );
};
