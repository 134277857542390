import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import Moment from "react-moment";
import { Logo } from "./fa";
import preval from "preval.macro";
import { Title } from "./title";
const buildDate = preval`module.exports = new Date().toISOString()`;

export const Layout = ({ location, user, signOut, children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          menu {
            title
            icon
            link
            show
          }
        }
      }
    }
  `);

  return (
    <>
      <header>
        <div className="header-banner p-3 p-lg-5">
          <div className="text-white d-flex flex-row p-0 pb-5">
            <Logo
              className="text-secondary fa-5x m-3 p-0"
              icon={"circle-nodes"}
            />
            <div>
              <div className="display-1 text-monospace">Sensor Network</div>
              <div className="pl-2 p">IOT Sensor Console</div>
            </div>
          </div>

          <hr className="border text-white m-0 p-0" />

          <nav className="p-0 pt-3 navbar navbar-expand-md navbar-dark">
            <Link title="Home" className="p-0 nav-link navbar-brand" to="/">
              Home
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="pl-3 collapse navbar-collapse justify-content-end"
              id="navbarSupportedContent"
            >
              <div className="nav navbar-nav text-right">
                {data.site.siteMetadata.menu
                  .filter((menu) => menu.show === true)
                  .filter(
                    (menu) =>
                      (menu.requiresAdmin === true &&
                        user.signInUserSession.accessToken.payload[
                          "cognito:groups"
                        ].includes("iot-webui-group-admin")) ||
                      !menu.requiresAdmin
                  )
                  .map((menu, i) => (
                    <span className="nav-item" key={i}>
                      <Link className="nav-link fa fa-home" to={menu.link}>
                        {" "}
                        {menu.title}
                      </Link>
                    </span>
                  ))}
                <span className="nav-item">
                  <Link
                    className="nav-link fa fa-home"
                    to="/"
                    onClick={signOut}
                  >
                    {" "}
                    Logout ({user.signInUserSession.idToken.payload.name})
                  </Link>
                </span>
              </div>
            </div>
          </nav>
        </div>
      </header>

      <main className="pt-3 pb-3 p-md-3 p-lg-5 container-fluid bg-light">
        <Title link={location.pathname} />
        {children}
      </main>

      <footer>
        <div className="p-3 mb-0">
          <div className="text-center text-white small">
            <p>
              &copy; exsertus.com <Moment format="YYYY">{buildDate}</Moment>
            </p>
            <p className="text-secondary">
              Build Date:
              <Moment format="Do MMMM YYYY HH:mm">{buildDate}</Moment>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};
